/* Looper styles */
@keyframes slideAnim {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

.looper {
    width: 100%;
    overflow: hidden;
}

.looper__innerList {
    display: flex;
    justify-content: center;
    width: fit-content;
}

.looper__innerList[data-animate='true'] .looper__listInstance {
    animation: slideAnim linear infinite;
}

.looper__listInstance {
    display: flex;
    width: max-content;

    animation: none;
}

/* Example content styles */

.banner {
    margin: 20px 0;
    border-top: 3px solid black;
    border-bottom: 3px solid black;
}

.bannerlabel {
    background: linear-gradient(
        to right,
        rgb(0, 0, 0) 70%,
        rgba(255, 255, 255, 0) 100%
    );
}

.bannerdesc {
    color: white;
    font-family: 'Roboto', sans-serif;
    margin-left: 15px;
}
