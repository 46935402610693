.faq-container {
    padding: 10%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.faq-h2 {
    text-decoration: underline;
    margin-bottom: 48px;
    color: #ba76e1;
}

.container-items {
    width: 90%;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-ul {
    margin-left: 50px;
}

.faq-question {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.arrow {
    rotate: 0deg;
    transition: 1s ease;
}

.open {
    rotate: 180deg;
    transition: 1s ease;
}

.faq-answer {
    font-size: x-large;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease;
}

.faq-answer.show {
    max-height: 400px; /* Arbitrarily large value to ensure it expands */
}

.faq-hr {
    border: 2px solid black;
    margin-top: 10px;
}
