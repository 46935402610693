.meet-the-team-container {
    padding: 5vh;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.meet-the-team-h2 {
    font-size: 50px;
    margin-bottom: 48px;
}

.meet-the-team-item {
    margin-bottom: 20px;
    font-size: 40px;
    border: 3px solid black;
    max-width: 500px;
    padding: 10px;
}

.meet-the-team-ul {
    margin-left: 50px;
}

.meet-the-team-question {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.arrow {
    rotate: 0deg;
    transition: 1s ease;
}

.open {
    rotate: 180deg;
    transition: 1s ease;
}

.meet-the-team-answer {
    font-size: x-large;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease;
}

.meet-the-team-answer.show {
    max-height: 400px; /* Arbitrarily large value to ensure it expands */
}
