.slide-in-left {
    opacity: 0;
    transform: translateX(20px);
    transition:
        opacity 0.5s,
        transform 2s;
}

.slide-in-right {
    opacity: 0;
    transform: translateX(-20px);
    transition:
        opacity 0.5s,
        transform 2s;
}

.slide-in-left.active,
.slide-in-right.active {
    opacity: 1;
    transform: translateX(0);
}

@keyframes slideDownFadeInZoomIn {
    0% {
        opacity: 0;
        transform: translateY(-50px) scale(0.8);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.slide-and-zoom {
    animation: slideDownFadeInZoomIn 1s ease-out;
}
