.conferencexbenefits-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: white;
    font-family: 'Tabular';
    font-style: normal;
    font-weight: 300;
    width: 100%;
    background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
        url('../../static/assets/outreach-gradient.jpeg') lightgray 50% / cover
            no-repeat;
}

.conferencexbenefits-content {
    font-size: 48px;
    min-width: 30%;
}

.conferencexbenefits-title {
    color: #bf94ff;
}

.conferencexbenefits-subtitle {
    color: white;
    margin-bottom: 10px;
    text-decoration: none;
}

.conferencexbenefits-event-button {
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.conferencexbenefits-event-button:hover {
    background-color: white;
    color: #2e0249;
}

.conferencexbenefits-details {
    font-family: 'Open Sans';
    display: flex;
    flex-direction: column;
    font-size: 40px;
}

.conferencexbenefits-detail-item {
    padding: 30px;
}

@media only screen and (max-width: 1000px) {
    .conferencexbenefits-container {
        flex-direction: column;
    }
    .conferencexbenefits-content {
        font-size: 30px;
    }
    .conferencexbenefits-details {
        font-size: 26px;
    }
    .conferencexbenefits-detail-item,
    .conferencexbenefits-content {
        padding: 10px;
    }
}

@media only screen and (max-width: 500px) {
    .conferencexbenefits-container {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0px;
        padding-right: 0px;
    }
    .conferencexbenefits-title {
        font-size: 30px;
    }
    .conferencexbenefits-subtitle {
        font-size: 30px;
    }
    .conferencexbenefits-details {
        font-size: 26px;
    }
    .conferencexbenefits-detail-item {
        padding: 10px;
    }
}
